import {
  CSP_NONCE,
  enableProdMode,
  importProvidersFrom,
  LOCALE_ID,
  TransferState
} from '@angular/core';
import { environment } from './environments/environment';
import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { CookieService } from 'ngx-cookie-service';
import {
  HttpClient,
  provideHttpClient,
  withInterceptors
} from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { routes } from './app/app-routing';
import { JwtInterceptor } from './app/interceptor/jwt.interceptor';
import { provideOAuthClient } from 'angular-oauth2-oidc';
import { ErrorInterceptor } from './app/interceptor/error.interceptor';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideIonicAngular } from '@ionic/angular/standalone';

registerLocaleData(localeFr, 'fr');
declare global {
  interface Window {
    myRandomNonceValue: string;
  }
}
export function getBaseUrl() {
  return document.getElementsByTagName('base')[0].href;
}

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    {
      provide: CSP_NONCE,
      useValue: window.myRandomNonceValue
    },
    importProvidersFrom(
      RouterModule.forRoot(routes, {
        scrollPositionRestoration: 'enabled',
        onSameUrlNavigation: 'reload',
        enableTracing: environment.tracing,
        anchorScrolling: 'enabled'
      })
    ),
    { provide: 'BASE_URL', useFactory: getBaseUrl, deps: [] },
    CookieService,
    provideHttpClient(withInterceptors([JwtInterceptor, ErrorInterceptor])),
    provideOAuthClient(),
    { provide: LOCALE_ID, useValue: 'fr' },
    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient, TransferState]
        }
      })
    ),
    provideIonicAngular({ mode: 'ios' }),
    provideAnimations()
  ]
}).catch((err) => console.log(err));

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}
